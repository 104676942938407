import { observable, action, computed } from 'mobx';
import { store } from '@/common/core/store/decorator';
import SimulationManagerStore from '@/newcar/core/store/simulationManagerStore';
import NEWCAR_CONST from '@/newcar/util/const';

export interface InterruptionItem {
  carId: string;
  maker: string;
  carName: string;
  src: string;
  price: number;
  term: number;
  grade: string;
  color: string;
  option: string;
  maintenance: string;
  href: string;
}

const MAX_COUNT = 5;

@store({ persist: true, name: 'interruption' })
export default class InterruptionStore {
  @observable interruptionItems: InterruptionItem[] = [];

  @action
  addItem(simulation: SimulationManagerStore, plan?: NEWCAR_CONST.SIMULATION_PLAN.ID): void {
    if (!simulation.nowCarDigest) {
      return;
    }
    // 同じ車種があったら削除
    this.removeByCarId(simulation.nowCarDigest.id__normalized);

    // プラン別に必要なデータを取得
    let price: number | undefined;
    let term: number | undefined;
    let grade: string | undefined;
    let color: string | undefined;
    let option: string | undefined;
    let maintenance: string | undefined;
    let href = `/detail/${simulation.nowCarDigest.id__normalized}/`;
    switch (plan) {
      case NEWCAR_CONST.SIMULATION_PLAN.ID.SIMPLE:
        price = simulation.nowSimpleSelection.price;
        term = simulation.nowSimpleSelection.term;
        grade = simulation.nowSimpleSelection.gradeName;
        color = simulation.nowSimpleSelection.color;
        option = simulation.nowSimpleSelection.option;
        maintenance = simulation.nowSimpleSelection.maintenance;
        break;
      case NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND:
        price = simulation.nowRecommendSelection.price;
        term = simulation.nowRecommendSelection.term;
        grade = simulation.nowRecommendSelection.gradeName;
        color = simulation.nowRecommendSelection.color;
        option = simulation.nowRecommendSelection.option;
        maintenance = simulation.nowRecommendSelection.maintenance;
        break;
      case NEWCAR_CONST.SIMULATION_PLAN.ID.RICH:
        price = simulation.nowRichSelection.price;
        term = simulation.nowRichSelection.term;
        grade = simulation.nowRichSelection.gradeName;
        color = simulation.nowRichSelection.color;
        option = simulation.nowRichSelection.option;
        maintenance = simulation.nowRichSelection.maintenance;
        break;
      case NEWCAR_CONST.SIMULATION_PLAN.ID.RECOMMEND_SP:
        price = simulation.nowRecommendSPSelection.price;
        term = simulation.nowRecommendSPSelection.term;
        grade = simulation.nowRecommendSPSelection.gradeName;
        color = simulation.nowRecommendSPSelection.color;
        option = simulation.nowRecommendSPSelection.option;
        maintenance = simulation.nowRecommendSPSelection.maintenance;
        break;
      default:
        price = simulation.nowSelectPrice;
        term = simulation.nowSelectTerm;
        grade = simulation.nowSelectGrade?.name;
        color = simulation.nowSelectBodyColor?.bodyColorName;
        option = simulation.nowSelectOption;
        maintenance = simulation.nowSelectMaintenance;
        href = `/detail/${simulation.nowCarDigest.id__normalized}/?${simulation.urlParams}`;
        break;
    }

    // 先頭に追加する
    this.interruptionItems.unshift({
      carId: simulation.nowCarDigest.id__normalized,
      maker: simulation.nowCarDigest.makerName,
      carName: simulation.nowCarDigest.name,
      src: simulation.nowCarDigest.thumbnailUrl,
      price: price || 0,
      term: term || 0,
      grade: grade || '',
      color: color || '',
      option: option || '',
      maintenance: maintenance || '',
      href,
    });

    // 最大件数を超えたら末尾を削除
    if (this.interruptionItems.length > MAX_COUNT) {
      this.interruptionItems.pop();
    }
  }

  @action
  removeByIndex(index: number): void {
    this.interruptionItems.splice(index, 1);
  }

  @action
  removeByCarId(carId: string): void {
    const index = this.interruptionItems.findIndex((item) => item.carId === carId);
    if (index !== -1) {
      this.interruptionItems.splice(index, 1);
    }
  }

  @computed
  get getItems(): InterruptionItem[] {
    return this.interruptionItems;
  }
}

export const interruptionStore = new InterruptionStore();
